import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "./providers/UserProvider";
import { Loader } from "./components/Loader/Loader";

export default function ProtectedRoute({ component: Component }) {
  const { user, loading } = useContext(UserContext);

  if (loading) {
    return <Loader />;
  }

  if (user) {
    return <Component />;
  }

  return <Navigate to="/authorization/login" />;
}
