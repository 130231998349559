/* tslint:disable */
/* eslint-disable */
/**
 * Electric cars
 * The backend API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActivationKeyResponseDTO } from '../models';
// @ts-ignore
import { CreateActivationKeyDTO } from '../models';
// @ts-ignore
import { ErrorDTO } from '../models';
// @ts-ignore
import { GetAllKeys200Response } from '../models';
// @ts-ignore
import { UpdateActionKeyDTO } from '../models';
/**
 * ActionKeyApi - axios parameter creator
 * @export
 */
export const ActionKeyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание ключа
         * @param {CreateActivationKeyDTO} createActivationKeyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKey: async (createActivationKeyDTO: CreateActivationKeyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createActivationKeyDTO' is not null or undefined
            assertParamExists('createKey', 'createActivationKeyDTO', createActivationKeyDTO)
            const localVarPath = `/activation-key/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createActivationKeyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить ключ
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKey: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteKey', 'id', id)
            const localVarPath = `/activation-key/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Все ключи
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {number} [key] 
         * @param {string} [ownerId] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKeys: async (page?: number, limit?: number, sort?: string, key?: number, ownerId?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activation-key/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ключа
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKey: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getKey', 'id', id)
            const localVarPath = `/activation-key/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение активного ключа юзера
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserActiveKey: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserActiveKey', 'id', id)
            const localVarPath = `/activation-key/active-key/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ключи пользователя
         * @param {string} id Id
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {number} [key] 
         * @param {string} [ownerId] 
         * @param {string} [id2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserKeys: async (id: string, page?: number, limit?: number, sort?: string, key?: number, ownerId?: string, id2?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserKeys', 'id', id)
            const localVarPath = `/activation-key/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (id2 !== undefined) {
                localVarQueryParameter['id'] = id2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить ключ
         * @param {UpdateActionKeyDTO} updateActionKeyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKey: async (updateActionKeyDTO: UpdateActionKeyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateActionKeyDTO' is not null or undefined
            assertParamExists('updateKey', 'updateActionKeyDTO', updateActionKeyDTO)
            const localVarPath = `/activation-key/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateActionKeyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionKeyApi - functional programming interface
 * @export
 */
export const ActionKeyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionKeyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создание ключа
         * @param {CreateActivationKeyDTO} createActivationKeyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createKey(createActivationKeyDTO: CreateActivationKeyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivationKeyResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createKey(createActivationKeyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить ключ
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteKey(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteKey(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Все ключи
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {number} [key] 
         * @param {string} [ownerId] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllKeys(page?: number, limit?: number, sort?: string, key?: number, ownerId?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllKeys200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllKeys(page, limit, sort, key, ownerId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение ключа
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKey(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivationKeyResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKey(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение активного ключа юзера
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserActiveKey(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivationKeyResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserActiveKey(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ключи пользователя
         * @param {string} id Id
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {number} [key] 
         * @param {string} [ownerId] 
         * @param {string} [id2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserKeys(id: string, page?: number, limit?: number, sort?: string, key?: number, ownerId?: string, id2?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllKeys200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserKeys(id, page, limit, sort, key, ownerId, id2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить ключ
         * @param {UpdateActionKeyDTO} updateActionKeyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateKey(updateActionKeyDTO: UpdateActionKeyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateKey(updateActionKeyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionKeyApi - factory interface
 * @export
 */
export const ActionKeyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionKeyApiFp(configuration)
    return {
        /**
         * 
         * @summary Создание ключа
         * @param {CreateActivationKeyDTO} createActivationKeyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKey(createActivationKeyDTO: CreateActivationKeyDTO, options?: any): AxiosPromise<ActivationKeyResponseDTO> {
            return localVarFp.createKey(createActivationKeyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить ключ
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKey(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteKey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Все ключи
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {number} [key] 
         * @param {string} [ownerId] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKeys(page?: number, limit?: number, sort?: string, key?: number, ownerId?: string, id?: string, options?: any): AxiosPromise<GetAllKeys200Response> {
            return localVarFp.getAllKeys(page, limit, sort, key, ownerId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение ключа
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKey(id: string, options?: any): AxiosPromise<ActivationKeyResponseDTO> {
            return localVarFp.getKey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение активного ключа юзера
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserActiveKey(id: string, options?: any): AxiosPromise<ActivationKeyResponseDTO> {
            return localVarFp.getUserActiveKey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ключи пользователя
         * @param {string} id Id
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {number} [key] 
         * @param {string} [ownerId] 
         * @param {string} [id2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserKeys(id: string, page?: number, limit?: number, sort?: string, key?: number, ownerId?: string, id2?: string, options?: any): AxiosPromise<GetAllKeys200Response> {
            return localVarFp.getUserKeys(id, page, limit, sort, key, ownerId, id2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить ключ
         * @param {UpdateActionKeyDTO} updateActionKeyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKey(updateActionKeyDTO: UpdateActionKeyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateKey(updateActionKeyDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActionKeyApi - interface
 * @export
 * @interface ActionKeyApi
 */
export interface ActionKeyApiInterface {
    /**
     * 
     * @summary Создание ключа
     * @param {CreateActivationKeyDTO} createActivationKeyDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApiInterface
     */
    createKey(createActivationKeyDTO: CreateActivationKeyDTO, options?: AxiosRequestConfig): AxiosPromise<ActivationKeyResponseDTO>;

    /**
     * 
     * @summary Удалить ключ
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApiInterface
     */
    deleteKey(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Все ключи
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {number} [key] 
     * @param {string} [ownerId] 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApiInterface
     */
    getAllKeys(page?: number, limit?: number, sort?: string, key?: number, ownerId?: string, id?: string, options?: AxiosRequestConfig): AxiosPromise<GetAllKeys200Response>;

    /**
     * 
     * @summary Получение ключа
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApiInterface
     */
    getKey(id: string, options?: AxiosRequestConfig): AxiosPromise<ActivationKeyResponseDTO>;

    /**
     * 
     * @summary Получение активного ключа юзера
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApiInterface
     */
    getUserActiveKey(id: string, options?: AxiosRequestConfig): AxiosPromise<ActivationKeyResponseDTO>;

    /**
     * 
     * @summary Ключи пользователя
     * @param {string} id Id
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {number} [key] 
     * @param {string} [ownerId] 
     * @param {string} [id2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApiInterface
     */
    getUserKeys(id: string, page?: number, limit?: number, sort?: string, key?: number, ownerId?: string, id2?: string, options?: AxiosRequestConfig): AxiosPromise<GetAllKeys200Response>;

    /**
     * 
     * @summary Обновить ключ
     * @param {UpdateActionKeyDTO} updateActionKeyDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApiInterface
     */
    updateKey(updateActionKeyDTO: UpdateActionKeyDTO, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ActionKeyApi - object-oriented interface
 * @export
 * @class ActionKeyApi
 * @extends {BaseAPI}
 */
export class ActionKeyApi extends BaseAPI implements ActionKeyApiInterface {
    /**
     * 
     * @summary Создание ключа
     * @param {CreateActivationKeyDTO} createActivationKeyDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApi
     */
    public createKey(createActivationKeyDTO: CreateActivationKeyDTO, options?: AxiosRequestConfig) {
        return ActionKeyApiFp(this.configuration).createKey(createActivationKeyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить ключ
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApi
     */
    public deleteKey(id: string, options?: AxiosRequestConfig) {
        return ActionKeyApiFp(this.configuration).deleteKey(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Все ключи
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {number} [key] 
     * @param {string} [ownerId] 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApi
     */
    public getAllKeys(page?: number, limit?: number, sort?: string, key?: number, ownerId?: string, id?: string, options?: AxiosRequestConfig) {
        return ActionKeyApiFp(this.configuration).getAllKeys(page, limit, sort, key, ownerId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение ключа
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApi
     */
    public getKey(id: string, options?: AxiosRequestConfig) {
        return ActionKeyApiFp(this.configuration).getKey(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение активного ключа юзера
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApi
     */
    public getUserActiveKey(id: string, options?: AxiosRequestConfig) {
        return ActionKeyApiFp(this.configuration).getUserActiveKey(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ключи пользователя
     * @param {string} id Id
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {number} [key] 
     * @param {string} [ownerId] 
     * @param {string} [id2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApi
     */
    public getUserKeys(id: string, page?: number, limit?: number, sort?: string, key?: number, ownerId?: string, id2?: string, options?: AxiosRequestConfig) {
        return ActionKeyApiFp(this.configuration).getUserKeys(id, page, limit, sort, key, ownerId, id2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить ключ
     * @param {UpdateActionKeyDTO} updateActionKeyDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionKeyApi
     */
    public updateKey(updateActionKeyDTO: UpdateActionKeyDTO, options?: AxiosRequestConfig) {
        return ActionKeyApiFp(this.configuration).updateKey(updateActionKeyDTO, options).then((request) => request(this.axios, this.basePath));
    }
}

