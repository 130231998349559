/* tslint:disable */
/* eslint-disable */
/**
 * Electric cars
 * The backend API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeEmailDTO } from '../models';
// @ts-ignore
import { ChangePasswordDTO } from '../models';
// @ts-ignore
import { ErrorDTO } from '../models';
// @ts-ignore
import { ProfileResponseDTO } from '../models';
// @ts-ignore
import { SendChangeEmailDTO } from '../models';
// @ts-ignore
import { UpdateProfileDTO } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Подтверждение смены email пользователя
         * @param {ChangeEmailDTO} changeEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail: async (changeEmailDTO: ChangeEmailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeEmailDTO' is not null or undefined
            assertParamExists('changeEmail', 'changeEmailDTO', changeEmailDTO)
            const localVarPath = `/user/email-change-confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeEmailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить пароль
         * @param {ChangePasswordDTO} changePasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (changePasswordDTO: ChangePasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordDTO' is not null or undefined
            assertParamExists('changePassword', 'changePasswordDTO', changePasswordDTO)
            const localVarPath = `/user/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Мой профиль
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправка письма с подтверждением на новую почту
         * @param {SendChangeEmailDTO} sendChangeEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendChangeEmail: async (sendChangeEmailDTO: SendChangeEmailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendChangeEmailDTO' is not null or undefined
            assertParamExists('sendChangeEmail', 'sendChangeEmailDTO', sendChangeEmailDTO)
            const localVarPath = `/user/email-change-send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendChangeEmailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить профиль
         * @param {UpdateProfileDTO} updateProfileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (updateProfileDTO: UpdateProfileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProfileDTO' is not null or undefined
            assertParamExists('updateProfile', 'updateProfileDTO', updateProfileDTO)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProfileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add or update an avatar
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAvatar: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadAvatar', 'file', file)
            const localVarPath = `/user/upload-image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Подтверждение смены email пользователя
         * @param {ChangeEmailDTO} changeEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEmail(changeEmailDTO: ChangeEmailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeEmail(changeEmailDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить пароль
         * @param {ChangePasswordDTO} changePasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(changePasswordDTO: ChangePasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(changePasswordDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Мой профиль
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отправка письма с подтверждением на новую почту
         * @param {SendChangeEmailDTO} sendChangeEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendChangeEmail(sendChangeEmailDTO: SendChangeEmailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendChangeEmail(sendChangeEmailDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить профиль
         * @param {UpdateProfileDTO} updateProfileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(updateProfileDTO: UpdateProfileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(updateProfileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add or update an avatar
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAvatar(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAvatar(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Подтверждение смены email пользователя
         * @param {ChangeEmailDTO} changeEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail(changeEmailDTO: ChangeEmailDTO, options?: any): AxiosPromise<void> {
            return localVarFp.changeEmail(changeEmailDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить пароль
         * @param {ChangePasswordDTO} changePasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePasswordDTO: ChangePasswordDTO, options?: any): AxiosPromise<void> {
            return localVarFp.changePassword(changePasswordDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Мой профиль
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<ProfileResponseDTO> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправка письма с подтверждением на новую почту
         * @param {SendChangeEmailDTO} sendChangeEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendChangeEmail(sendChangeEmailDTO: SendChangeEmailDTO, options?: any): AxiosPromise<void> {
            return localVarFp.sendChangeEmail(sendChangeEmailDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить профиль
         * @param {UpdateProfileDTO} updateProfileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(updateProfileDTO: UpdateProfileDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateProfile(updateProfileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add or update an avatar
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAvatar(file: File, options?: any): AxiosPromise<string> {
            return localVarFp.uploadAvatar(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
    /**
     * 
     * @summary Подтверждение смены email пользователя
     * @param {ChangeEmailDTO} changeEmailDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    changeEmail(changeEmailDTO: ChangeEmailDTO, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Обновить пароль
     * @param {ChangePasswordDTO} changePasswordDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    changePassword(changePasswordDTO: ChangePasswordDTO, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Мой профиль
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getProfile(options?: AxiosRequestConfig): AxiosPromise<ProfileResponseDTO>;

    /**
     * 
     * @summary Отправка письма с подтверждением на новую почту
     * @param {SendChangeEmailDTO} sendChangeEmailDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    sendChangeEmail(sendChangeEmailDTO: SendChangeEmailDTO, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Обновить профиль
     * @param {UpdateProfileDTO} updateProfileDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateProfile(updateProfileDTO: UpdateProfileDTO, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Add or update an avatar
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    uploadAvatar(file: File, options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
    /**
     * 
     * @summary Подтверждение смены email пользователя
     * @param {ChangeEmailDTO} changeEmailDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changeEmail(changeEmailDTO: ChangeEmailDTO, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).changeEmail(changeEmailDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить пароль
     * @param {ChangePasswordDTO} changePasswordDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changePassword(changePasswordDTO: ChangePasswordDTO, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).changePassword(changePasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Мой профиль
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getProfile(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправка письма с подтверждением на новую почту
     * @param {SendChangeEmailDTO} sendChangeEmailDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sendChangeEmail(sendChangeEmailDTO: SendChangeEmailDTO, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).sendChangeEmail(sendChangeEmailDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить профиль
     * @param {UpdateProfileDTO} updateProfileDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateProfile(updateProfileDTO: UpdateProfileDTO, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateProfile(updateProfileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add or update an avatar
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public uploadAvatar(file: File, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).uploadAvatar(file, options).then((request) => request(this.axios, this.basePath));
    }
}

