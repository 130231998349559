/* tslint:disable */
/* eslint-disable */
/**
 * Electric cars
 * The backend API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorDTO } from '../models';
// @ts-ignore
import { ForgotPasswordDTO } from '../models';
// @ts-ignore
import { RefreshTokenResponseDTO } from '../models';
// @ts-ignore
import { ResetPasswordDTO } from '../models';
// @ts-ignore
import { SendMailCodeDTO } from '../models';
// @ts-ignore
import { SignInEmailDTO } from '../models';
// @ts-ignore
import { SignInResponseDTO } from '../models';
// @ts-ignore
import { SignUpUserDTO } from '../models';
// @ts-ignore
import { UserInfoResponseDTO } from '../models';
// @ts-ignore
import { VerifyEmailDTO } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Забыли пароль
         * @param {ForgotPasswordDTO} forgotPasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordDTO: ForgotPasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordDTO' is not null or undefined
            assertParamExists('forgotPassword', 'forgotPasswordDTO', forgotPasswordDTO)
            const localVarPath = `/auth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Данные авторизованного пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/user-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Используйте refreshToken для авторизации этого запроса
         * @summary Обновить токен
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokens: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправка письма с подтверждением email
         * @param {SendMailCodeDTO} sendMailCodeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendVerifyCode: async (sendMailCodeDTO: SendMailCodeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendMailCodeDTO' is not null or undefined
            assertParamExists('resendVerifyCode', 'sendMailCodeDTO', sendMailCodeDTO)
            const localVarPath = `/auth/resend-verify-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMailCodeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сброс пароля
         * @param {ResetPasswordDTO} resetPasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordDTO: ResetPasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordDTO' is not null or undefined
            assertParamExists('resetPassword', 'resetPasswordDTO', resetPasswordDTO)
            const localVarPath = `/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Авторизация по емайлу и паролю
         * @param {SignInEmailDTO} signInEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInEmail: async (signInEmailDTO: SignInEmailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signInEmailDTO' is not null or undefined
            assertParamExists('signInEmail', 'signInEmailDTO', signInEmailDTO)
            const localVarPath = `/auth/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInEmailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Регистрация администратора с повышенными полномочиями
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUpSuperAdmin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/superadmin/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Регистрация пользователя
         * @param {SignUpUserDTO} signUpUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUpUser: async (signUpUserDTO: SignUpUserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpUserDTO' is not null or undefined
            assertParamExists('signUpUser', 'signUpUserDTO', signUpUserDTO)
            const localVarPath = `/auth/signup-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подтверждение email пользователя
         * @param {VerifyEmailDTO} verifyEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUserEmail: async (verifyEmailDTO: VerifyEmailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmailDTO' is not null or undefined
            assertParamExists('verifyUserEmail', 'verifyEmailDTO', verifyEmailDTO)
            const localVarPath = `/auth/email-verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Забыли пароль
         * @param {ForgotPasswordDTO} forgotPasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordDTO: ForgotPasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Данные авторизованного пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Используйте refreshToken для авторизации этого запроса
         * @summary Обновить токен
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshTokens(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshTokenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshTokens(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отправка письма с подтверждением email
         * @param {SendMailCodeDTO} sendMailCodeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendVerifyCode(sendMailCodeDTO: SendMailCodeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendVerifyCode(sendMailCodeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сброс пароля
         * @param {ResetPasswordDTO} resetPasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordDTO: ResetPasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Авторизация по емайлу и паролю
         * @param {SignInEmailDTO} signInEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signInEmail(signInEmailDTO: SignInEmailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignInResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signInEmail(signInEmailDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Регистрация администратора с повышенными полномочиями
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUpSuperAdmin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUpSuperAdmin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Регистрация пользователя
         * @param {SignUpUserDTO} signUpUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUpUser(signUpUserDTO: SignUpUserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUpUser(signUpUserDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подтверждение email пользователя
         * @param {VerifyEmailDTO} verifyEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyUserEmail(verifyEmailDTO: VerifyEmailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyUserEmail(verifyEmailDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Забыли пароль
         * @param {ForgotPasswordDTO} forgotPasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordDTO: ForgotPasswordDTO, options?: any): AxiosPromise<void> {
            return localVarFp.forgotPassword(forgotPasswordDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Данные авторизованного пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any): AxiosPromise<UserInfoResponseDTO> {
            return localVarFp.getUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Используйте refreshToken для авторизации этого запроса
         * @summary Обновить токен
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokens(options?: any): AxiosPromise<RefreshTokenResponseDTO> {
            return localVarFp.refreshTokens(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправка письма с подтверждением email
         * @param {SendMailCodeDTO} sendMailCodeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendVerifyCode(sendMailCodeDTO: SendMailCodeDTO, options?: any): AxiosPromise<void> {
            return localVarFp.resendVerifyCode(sendMailCodeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сброс пароля
         * @param {ResetPasswordDTO} resetPasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordDTO: ResetPasswordDTO, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(resetPasswordDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Авторизация по емайлу и паролю
         * @param {SignInEmailDTO} signInEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInEmail(signInEmailDTO: SignInEmailDTO, options?: any): AxiosPromise<SignInResponseDTO> {
            return localVarFp.signInEmail(signInEmailDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Регистрация администратора с повышенными полномочиями
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUpSuperAdmin(options?: any): AxiosPromise<void> {
            return localVarFp.signUpSuperAdmin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Регистрация пользователя
         * @param {SignUpUserDTO} signUpUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUpUser(signUpUserDTO: SignUpUserDTO, options?: any): AxiosPromise<void> {
            return localVarFp.signUpUser(signUpUserDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подтверждение email пользователя
         * @param {VerifyEmailDTO} verifyEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUserEmail(verifyEmailDTO: VerifyEmailDTO, options?: any): AxiosPromise<UserInfoResponseDTO> {
            return localVarFp.verifyUserEmail(verifyEmailDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
    /**
     * 
     * @summary Забыли пароль
     * @param {ForgotPasswordDTO} forgotPasswordDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    forgotPassword(forgotPasswordDTO: ForgotPasswordDTO, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Данные авторизованного пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    getUserInfo(options?: AxiosRequestConfig): AxiosPromise<UserInfoResponseDTO>;

    /**
     * Используйте refreshToken для авторизации этого запроса
     * @summary Обновить токен
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    refreshTokens(options?: AxiosRequestConfig): AxiosPromise<RefreshTokenResponseDTO>;

    /**
     * 
     * @summary Отправка письма с подтверждением email
     * @param {SendMailCodeDTO} sendMailCodeDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    resendVerifyCode(sendMailCodeDTO: SendMailCodeDTO, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Сброс пароля
     * @param {ResetPasswordDTO} resetPasswordDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    resetPassword(resetPasswordDTO: ResetPasswordDTO, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Авторизация по емайлу и паролю
     * @param {SignInEmailDTO} signInEmailDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    signInEmail(signInEmailDTO: SignInEmailDTO, options?: AxiosRequestConfig): AxiosPromise<SignInResponseDTO>;

    /**
     * 
     * @summary Регистрация администратора с повышенными полномочиями
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    signUpSuperAdmin(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Регистрация пользователя
     * @param {SignUpUserDTO} signUpUserDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    signUpUser(signUpUserDTO: SignUpUserDTO, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Подтверждение email пользователя
     * @param {VerifyEmailDTO} verifyEmailDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    verifyUserEmail(verifyEmailDTO: VerifyEmailDTO, options?: AxiosRequestConfig): AxiosPromise<UserInfoResponseDTO>;

}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
    /**
     * 
     * @summary Забыли пароль
     * @param {ForgotPasswordDTO} forgotPasswordDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public forgotPassword(forgotPasswordDTO: ForgotPasswordDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).forgotPassword(forgotPasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Данные авторизованного пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getUserInfo(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Используйте refreshToken для авторизации этого запроса
     * @summary Обновить токен
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshTokens(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).refreshTokens(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправка письма с подтверждением email
     * @param {SendMailCodeDTO} sendMailCodeDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resendVerifyCode(sendMailCodeDTO: SendMailCodeDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).resendVerifyCode(sendMailCodeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сброс пароля
     * @param {ResetPasswordDTO} resetPasswordDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetPassword(resetPasswordDTO: ResetPasswordDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).resetPassword(resetPasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Авторизация по емайлу и паролю
     * @param {SignInEmailDTO} signInEmailDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signInEmail(signInEmailDTO: SignInEmailDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).signInEmail(signInEmailDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Регистрация администратора с повышенными полномочиями
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signUpSuperAdmin(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).signUpSuperAdmin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Регистрация пользователя
     * @param {SignUpUserDTO} signUpUserDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signUpUser(signUpUserDTO: SignUpUserDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).signUpUser(signUpUserDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подтверждение email пользователя
     * @param {VerifyEmailDTO} verifyEmailDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public verifyUserEmail(verifyEmailDTO: VerifyEmailDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).verifyUserEmail(verifyEmailDTO, options).then((request) => request(this.axios, this.basePath));
    }
}

