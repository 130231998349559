/* tslint:disable */
/* eslint-disable */
/**
 * Electric cars
 * The backend API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateProjectDTO } from '../models';
// @ts-ignore
import { ErrorDTO } from '../models';
// @ts-ignore
import { GetAllProjects200Response } from '../models';
// @ts-ignore
import { ProjectResponseDTO } from '../models';
// @ts-ignore
import { UpdateProjectDTO } from '../models';
/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание проекта
         * @param {CreateProjectDTO} createProjectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (createProjectDTO: CreateProjectDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProjectDTO' is not null or undefined
            assertParamExists('createProject', 'createProjectDTO', createProjectDTO)
            const localVarPath = `/project/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить проект
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProject', 'id', id)
            const localVarPath = `/project/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Все проекты
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjects: async (page?: number, limit?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение проекта
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProject', 'id', id)
            const localVarPath = `/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Проекты пользователя
         * @param {string} id Id
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjects: async (id: string, page?: number, limit?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserProjects', 'id', id)
            const localVarPath = `/project/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить проект
         * @param {UpdateProjectDTO} updateProjectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (updateProjectDTO: UpdateProjectDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProjectDTO' is not null or undefined
            assertParamExists('updateProject', 'updateProjectDTO', updateProjectDTO)
            const localVarPath = `/project/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создание проекта
         * @param {CreateProjectDTO} createProjectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(createProjectDTO: CreateProjectDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(createProjectDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить проект
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Все проекты
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjects(page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllProjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProjects(page, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение проекта
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Проекты пользователя
         * @param {string} id Id
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProjects(id: string, page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllProjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProjects(id, page, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить проект
         * @param {UpdateProjectDTO} updateProjectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(updateProjectDTO: UpdateProjectDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(updateProjectDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @summary Создание проекта
         * @param {CreateProjectDTO} createProjectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(createProjectDTO: CreateProjectDTO, options?: any): AxiosPromise<ProjectResponseDTO> {
            return localVarFp.createProject(createProjectDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить проект
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Все проекты
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjects(page?: number, limit?: number, sort?: string, options?: any): AxiosPromise<GetAllProjects200Response> {
            return localVarFp.getAllProjects(page, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение проекта
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(id: string, options?: any): AxiosPromise<ProjectResponseDTO> {
            return localVarFp.getProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Проекты пользователя
         * @param {string} id Id
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjects(id: string, page?: number, limit?: number, sort?: string, options?: any): AxiosPromise<GetAllProjects200Response> {
            return localVarFp.getUserProjects(id, page, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить проект
         * @param {UpdateProjectDTO} updateProjectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(updateProjectDTO: UpdateProjectDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateProject(updateProjectDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - interface
 * @export
 * @interface ProjectApi
 */
export interface ProjectApiInterface {
    /**
     * 
     * @summary Создание проекта
     * @param {CreateProjectDTO} createProjectDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    createProject(createProjectDTO: CreateProjectDTO, options?: AxiosRequestConfig): AxiosPromise<ProjectResponseDTO>;

    /**
     * 
     * @summary Удалить проект
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    deleteProject(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Все проекты
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    getAllProjects(page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): AxiosPromise<GetAllProjects200Response>;

    /**
     * 
     * @summary Получение проекта
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    getProject(id: string, options?: AxiosRequestConfig): AxiosPromise<ProjectResponseDTO>;

    /**
     * 
     * @summary Проекты пользователя
     * @param {string} id Id
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    getUserProjects(id: string, page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): AxiosPromise<GetAllProjects200Response>;

    /**
     * 
     * @summary Обновить проект
     * @param {UpdateProjectDTO} updateProjectDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    updateProject(updateProjectDTO: UpdateProjectDTO, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI implements ProjectApiInterface {
    /**
     * 
     * @summary Создание проекта
     * @param {CreateProjectDTO} createProjectDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public createProject(createProjectDTO: CreateProjectDTO, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).createProject(createProjectDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить проект
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteProject(id: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).deleteProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Все проекты
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getAllProjects(page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getAllProjects(page, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение проекта
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProject(id: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Проекты пользователя
     * @param {string} id Id
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getUserProjects(id: string, page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getUserProjects(id, page, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить проект
     * @param {UpdateProjectDTO} updateProjectDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public updateProject(updateProjectDTO: UpdateProjectDTO, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).updateProject(updateProjectDTO, options).then((request) => request(this.axios, this.basePath));
    }
}

