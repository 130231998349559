/* tslint:disable */
/* eslint-disable */
/**
 * Electric cars
 * The backend API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorDTO } from '../models';
// @ts-ignore
import { GetList200Response } from '../models';
// @ts-ignore
import { ProfileResponseDTO } from '../models';
// @ts-ignore
import { SignUpAdminDTO } from '../models';
// @ts-ignore
import { UpdateByAdminProfileDTO } from '../models';
/**
 * AdminUserApi - axios parameter creator
 * @export
 */
export const AdminUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Профиль пользователя
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProfile: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGetProfile', 'id', id)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить профиль пользователя
         * @param {string} id Id
         * @param {UpdateByAdminProfileDTO} updateByAdminProfileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateProfile: async (id: string, updateByAdminProfileDTO: UpdateByAdminProfileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUpdateProfile', 'id', id)
            // verify required parameter 'updateByAdminProfileDTO' is not null or undefined
            assertParamExists('adminUpdateProfile', 'updateByAdminProfileDTO', updateByAdminProfileDTO)
            const localVarPath = `/user/user-change/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateByAdminProfileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список пользователей
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {GetListRoleEnum} [role] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList: async (page?: number, limit?: number, role?: GetListRoleEnum, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Регистрация администратора
         * @param {SignUpAdminDTO} signUpAdminDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUpAdmin: async (signUpAdminDTO: SignUpAdminDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpAdminDTO' is not null or undefined
            assertParamExists('signUpAdmin', 'signUpAdminDTO', signUpAdminDTO)
            const localVarPath = `/user/admin/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpAdminDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUserApi - functional programming interface
 * @export
 */
export const AdminUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Профиль пользователя
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetProfile(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetProfile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить профиль пользователя
         * @param {string} id Id
         * @param {UpdateByAdminProfileDTO} updateByAdminProfileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateProfile(id: string, updateByAdminProfileDTO: UpdateByAdminProfileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateProfile(id, updateByAdminProfileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список пользователей
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {GetListRoleEnum} [role] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getList(page?: number, limit?: number, role?: GetListRoleEnum, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getList(page, limit, role, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Регистрация администратора
         * @param {SignUpAdminDTO} signUpAdminDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUpAdmin(signUpAdminDTO: SignUpAdminDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUpAdmin(signUpAdminDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminUserApi - factory interface
 * @export
 */
export const AdminUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUserApiFp(configuration)
    return {
        /**
         * 
         * @summary Профиль пользователя
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProfile(id: string, options?: any): AxiosPromise<ProfileResponseDTO> {
            return localVarFp.adminGetProfile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить профиль пользователя
         * @param {string} id Id
         * @param {UpdateByAdminProfileDTO} updateByAdminProfileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateProfile(id: string, updateByAdminProfileDTO: UpdateByAdminProfileDTO, options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateProfile(id, updateByAdminProfileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список пользователей
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {GetListRoleEnum} [role] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(page?: number, limit?: number, role?: GetListRoleEnum, sort?: string, options?: any): AxiosPromise<GetList200Response> {
            return localVarFp.getList(page, limit, role, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Регистрация администратора
         * @param {SignUpAdminDTO} signUpAdminDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUpAdmin(signUpAdminDTO: SignUpAdminDTO, options?: any): AxiosPromise<void> {
            return localVarFp.signUpAdmin(signUpAdminDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUserApi - interface
 * @export
 * @interface AdminUserApi
 */
export interface AdminUserApiInterface {
    /**
     * 
     * @summary Профиль пользователя
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApiInterface
     */
    adminGetProfile(id: string, options?: AxiosRequestConfig): AxiosPromise<ProfileResponseDTO>;

    /**
     * 
     * @summary Обновить профиль пользователя
     * @param {string} id Id
     * @param {UpdateByAdminProfileDTO} updateByAdminProfileDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApiInterface
     */
    adminUpdateProfile(id: string, updateByAdminProfileDTO: UpdateByAdminProfileDTO, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Список пользователей
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {GetListRoleEnum} [role] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApiInterface
     */
    getList(page?: number, limit?: number, role?: GetListRoleEnum, sort?: string, options?: AxiosRequestConfig): AxiosPromise<GetList200Response>;

    /**
     * 
     * @summary Регистрация администратора
     * @param {SignUpAdminDTO} signUpAdminDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApiInterface
     */
    signUpAdmin(signUpAdminDTO: SignUpAdminDTO, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminUserApi - object-oriented interface
 * @export
 * @class AdminUserApi
 * @extends {BaseAPI}
 */
export class AdminUserApi extends BaseAPI implements AdminUserApiInterface {
    /**
     * 
     * @summary Профиль пользователя
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public adminGetProfile(id: string, options?: AxiosRequestConfig) {
        return AdminUserApiFp(this.configuration).adminGetProfile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить профиль пользователя
     * @param {string} id Id
     * @param {UpdateByAdminProfileDTO} updateByAdminProfileDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public adminUpdateProfile(id: string, updateByAdminProfileDTO: UpdateByAdminProfileDTO, options?: AxiosRequestConfig) {
        return AdminUserApiFp(this.configuration).adminUpdateProfile(id, updateByAdminProfileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список пользователей
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {GetListRoleEnum} [role] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public getList(page?: number, limit?: number, role?: GetListRoleEnum, sort?: string, options?: AxiosRequestConfig) {
        return AdminUserApiFp(this.configuration).getList(page, limit, role, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Регистрация администратора
     * @param {SignUpAdminDTO} signUpAdminDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public signUpAdmin(signUpAdminDTO: SignUpAdminDTO, options?: AxiosRequestConfig) {
        return AdminUserApiFp(this.configuration).signUpAdmin(signUpAdminDTO, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetListRoleEnum = {
    User: 'user',
    Admin: 'admin',
    SuperAdmin: 'superAdmin'
} as const;
export type GetListRoleEnum = typeof GetListRoleEnum[keyof typeof GetListRoleEnum];
