/* tslint:disable */
/* eslint-disable */
/**
 * Electric cars
 * The backend API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateTransactionDTO } from '../models';
// @ts-ignore
import { ErrorDTO } from '../models';
// @ts-ignore
import { GetAllTransactions200Response } from '../models';
// @ts-ignore
import { TransactionResponseDTO } from '../models';
// @ts-ignore
import { UpdateTransactionDTO } from '../models';
/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание транзакции
         * @param {CreateTransactionDTO} createTransactionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction: async (createTransactionDTO: CreateTransactionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTransactionDTO' is not null or undefined
            assertParamExists('createTransaction', 'createTransactionDTO', createTransactionDTO)
            const localVarPath = `/transaction/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTransactionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить транзакцию
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransaction: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTransaction', 'id', id)
            const localVarPath = `/transaction/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Все транзакции
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTransactions: async (page?: number, limit?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transaction/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Транзакции проекта
         * @param {string} id Id
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTransactions: async (id: string, page?: number, limit?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectTransactions', 'id', id)
            const localVarPath = `/transaction/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение транзакции
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTransaction', 'id', id)
            const localVarPath = `/transaction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Транзакции пользователя
         * @param {string} id Id
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTransactions: async (id: string, page?: number, limit?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserTransactions', 'id', id)
            const localVarPath = `/transaction/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить транзакцию
         * @param {UpdateTransactionDTO} updateTransactionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransaction: async (updateTransactionDTO: UpdateTransactionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTransactionDTO' is not null or undefined
            assertParamExists('updateTransaction', 'updateTransactionDTO', updateTransactionDTO)
            const localVarPath = `/transaction/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTransactionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создание транзакции
         * @param {CreateTransactionDTO} createTransactionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransaction(createTransactionDTO: CreateTransactionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransaction(createTransactionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить транзакцию
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransaction(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransaction(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Все транзакции
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTransactions(page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllTransactions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTransactions(page, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Транзакции проекта
         * @param {string} id Id
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectTransactions(id: string, page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllTransactions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectTransactions(id, page, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение транзакции
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransaction(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransaction(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Транзакции пользователя
         * @param {string} id Id
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserTransactions(id: string, page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllTransactions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserTransactions(id, page, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить транзакцию
         * @param {UpdateTransactionDTO} updateTransactionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransaction(updateTransactionDTO: UpdateTransactionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransaction(updateTransactionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionApiFp(configuration)
    return {
        /**
         * 
         * @summary Создание транзакции
         * @param {CreateTransactionDTO} createTransactionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction(createTransactionDTO: CreateTransactionDTO, options?: any): AxiosPromise<TransactionResponseDTO> {
            return localVarFp.createTransaction(createTransactionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить транзакцию
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransaction(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTransaction(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Все транзакции
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTransactions(page?: number, limit?: number, sort?: string, options?: any): AxiosPromise<GetAllTransactions200Response> {
            return localVarFp.getAllTransactions(page, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Транзакции проекта
         * @param {string} id Id
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTransactions(id: string, page?: number, limit?: number, sort?: string, options?: any): AxiosPromise<GetAllTransactions200Response> {
            return localVarFp.getProjectTransactions(id, page, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение транзакции
         * @param {string} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction(id: string, options?: any): AxiosPromise<TransactionResponseDTO> {
            return localVarFp.getTransaction(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Транзакции пользователя
         * @param {string} id Id
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTransactions(id: string, page?: number, limit?: number, sort?: string, options?: any): AxiosPromise<GetAllTransactions200Response> {
            return localVarFp.getUserTransactions(id, page, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить транзакцию
         * @param {UpdateTransactionDTO} updateTransactionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransaction(updateTransactionDTO: UpdateTransactionDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateTransaction(updateTransactionDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionApi - interface
 * @export
 * @interface TransactionApi
 */
export interface TransactionApiInterface {
    /**
     * 
     * @summary Создание транзакции
     * @param {CreateTransactionDTO} createTransactionDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApiInterface
     */
    createTransaction(createTransactionDTO: CreateTransactionDTO, options?: AxiosRequestConfig): AxiosPromise<TransactionResponseDTO>;

    /**
     * 
     * @summary Удалить транзакцию
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApiInterface
     */
    deleteTransaction(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Все транзакции
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApiInterface
     */
    getAllTransactions(page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): AxiosPromise<GetAllTransactions200Response>;

    /**
     * 
     * @summary Транзакции проекта
     * @param {string} id Id
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApiInterface
     */
    getProjectTransactions(id: string, page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): AxiosPromise<GetAllTransactions200Response>;

    /**
     * 
     * @summary Получение транзакции
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApiInterface
     */
    getTransaction(id: string, options?: AxiosRequestConfig): AxiosPromise<TransactionResponseDTO>;

    /**
     * 
     * @summary Транзакции пользователя
     * @param {string} id Id
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApiInterface
     */
    getUserTransactions(id: string, page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): AxiosPromise<GetAllTransactions200Response>;

    /**
     * 
     * @summary Обновить транзакцию
     * @param {UpdateTransactionDTO} updateTransactionDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApiInterface
     */
    updateTransaction(updateTransactionDTO: UpdateTransactionDTO, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI implements TransactionApiInterface {
    /**
     * 
     * @summary Создание транзакции
     * @param {CreateTransactionDTO} createTransactionDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public createTransaction(createTransactionDTO: CreateTransactionDTO, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).createTransaction(createTransactionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить транзакцию
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public deleteTransaction(id: string, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).deleteTransaction(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Все транзакции
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getAllTransactions(page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).getAllTransactions(page, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Транзакции проекта
     * @param {string} id Id
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getProjectTransactions(id: string, page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).getProjectTransactions(id, page, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение транзакции
     * @param {string} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getTransaction(id: string, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).getTransaction(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Транзакции пользователя
     * @param {string} id Id
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getUserTransactions(id: string, page?: number, limit?: number, sort?: string, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).getUserTransactions(id, page, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить транзакцию
     * @param {UpdateTransactionDTO} updateTransactionDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public updateTransaction(updateTransactionDTO: UpdateTransactionDTO, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).updateTransaction(updateTransactionDTO, options).then((request) => request(this.axios, this.basePath));
    }
}

